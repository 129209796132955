import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import twitter from "../images/twitter-icon.png";
import discord from "../images/discord-icon.png";
export const Footer = () => {
  return (
    <>
      <div className="footer row" style={{ position: "relative", zIndex: 10 }}>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 d-flex justify-content-lg-center justiy-content-start align-items-center my-2">
          nekomimi kat klub © 2022
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 d-flex justify-content-lg-center justiy-content-end align-items-center my-2">
          <a
            href="https://my.forms.app/form/63de67506c5c2579e21a91f1"
            target={"_blank"}
            style={{
              textDecoration: "none",
              fontSize: "inherit",
              color: "white",
            }}
          >
            Whitelist form
          </a>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 d-flex justify-content-lg-center justiy-content-start align-items-center my-2">
          <a
            href="/privacy-policy"
            target={"_blank"}
            style={{
              textDecoration: "none",
              fontSize: "inherit",
              color: "white",
            }}
          >
            Terms & conditions
          </a>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 d-flex justify-content-lg-center justiy-content-end align-items-center my-2">
          <div>
            <a href="https://discord.com/invite/HYbwZEgysj" target={"_blank"}>
              <img src={discord} alt="" style={{ marginRight: "1rem" }} />
            </a>
            <a
              href="https://twitter.com/nekokatclubnft"
              target={"_blank"}
            >
              <img src={twitter} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
